import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage, StaticImage } from 'gatsby-plugin-image'
import SectionHeader from '../components/SectionHeader'
import Layout from '../layout/Layout'
import config from '../config/theme'
import SectionImageText from '../components/SectionImageText'
import SectionIconsSlider from '../components/SectionIconsSlider'
import SectionBgImageText from '../components/SectionBgImageText'
import IconFlota from '../images/icons/flota.svg'
import IconParasol from '../images/icons/parasol.svg'
import IconSkarbonka from '../images/icons/skarbonka.svg'
import IconElastycznosc from '../images/icons/elastycznosc.svg'
import IconPracownik from '../images/icons/pracownik.svg'
import IconKalendarz from '../images/icons/kalendarz.svg'

export default function Home() {
  const { headerBgMobile, headerBg, sectionBgMobile, sectionBg } =
    useStaticQuery(
      graphql`
        query {
          headerBgMobile: file(
            relativePath: { eq: "nasze-uslugi/nasze-uslugi-bg-mobile.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData
            }
          }
          headerBg: file(
            relativePath: { eq: "nasze-uslugi/nasze-uslugi-bg.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData
            }
          }
          sectionBgMobile: file(
            relativePath: { eq: "nasze-uslugi/profesjonalizm-bg-mobile.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData
            }
          }
          sectionBg: file(
            relativePath: { eq: "nasze-uslugi/profesjonalizm-bg.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      `
    )

  const headerImages = [
    { ...getImage(headerBgMobile) },
    {
      ...getImage(headerBg),
      media: `(min-width: ${config.base.breakpoints.lgUp})`,
    },
  ]

  const sectionImages = [
    { ...getImage(sectionBgMobile) },
    {
      ...getImage(sectionBg),
      media: `(min-width: ${config.base.breakpoints.lgUp})`,
    },
  ]

  return (
    <Layout
      metaTitle="Nasze usługi"
      metaDescription="Jesteśmy firmą, która od wielu lat zapewnia Klientom krajowy i międzynarodowy transport drogowy w standardowym i specjalistycznym zakresie."
      slug="/uslugi"
    >
      <SectionHeader
        smallTitle="Usługi"
        title={
          <>
            Nasze <br />
            usługi
          </>
        }
        titleBorder="white"
        lead="Pakiet świadczonych przez nas usług jest bardzo szeroki - obejmuje przewóz ładunków zarówno standardowych, jak i ponadgabarytowych."
        content="Przyjmujemy także zlecenia na transport materiałów niebezpiecznych oraz towarów, które wymagają specjalnych warunków termicznych. Klienci, którzy decydują się na współpracę z nami, mogą liczyć na kompleksową obsługę. Pomagamy przy wyborze optymalnych rozwiązań logistycznych oraz załatwieniu formalności, w tym m.in. w zaklasyfikowaniu ładunku do odpowiedniej grupy, skompletowaniu niezbędnej dokumentacji, czy dopasowaniu specjalistycznego pojazdu. Obsługujemy transport drogowy krajowy i zagraniczny, w tym kabotażowy."
        scroll="#czytaj-wiecej"
        bgImage={headerImages}
      />
      <SectionImageText
        id="czytaj-wiecej"
        smallTitle="Usługi"
        title={
          <>
            Transport <br />
            krajowy
          </>
        }
        content={
          <>
            Dostarczenie towaru to jeden z&nbsp;istotnych etapów składających
            się na działalność gospodarczą milionów polskich firm. Od jego
            realizacji w&nbsp;dużym stopniu zależy efektywność Twojego biznesu,
            dlatego warto dobrze dobrać podwykonawcę. Jeśli go poszukujesz,
            znajdujesz się we właściwym miejscu. Krajowy transport drogowy jest
            dziedziną, w&nbsp;której specjalizujemy się od lat – to jedna
            z&nbsp;głównych gałęzi naszej działalności. Doświadczony zespół
            spedytorów zapewnia kompleksową obsługę i&nbsp;kontrolę procesów
            transportowych na każdym etapie.
          </>
        }
        image={
          <StaticImage
            src="../images/nasze-uslugi/transport-krajowy.jpg"
            alt=""
          />
        }
        bgText="01"
        page="/uslugi/transport-krajowy"
        imageFullWidth
      />
      <SectionImageText
        smallTitle="Usługi"
        title={
          <>
            Transport <br />
            międzynarodowy
          </>
        }
        content={
          <>
            Posiadamy uprawnienia zezwalające na międzynarodowy przewóz
            ładunków. Naszym zadaniem jest dostarczenie produktu z&nbsp;jednego
            punktu do drugiego w&nbsp;najbardziej efektywny, czyli bezpieczny
            i&nbsp;opłacalny dla Klienta sposób. Przewozimy dowolny towar, m.in.
            na paletach czy w&nbsp;specjalistycznych chłodniach. Kierowcy
            doskonale radzą sobie na zagranicznych trasach i&nbsp;terminowo
            dostarczają towar zarówno neutralny, jak i&nbsp;materiały
            niebezpieczne, czy produkty w&nbsp;kontrolowanej temperaturze.
          </>
        }
        image={
          <StaticImage
            src="../images/nasze-uslugi/transport-miedzynarodowy.jpg"
            alt=""
          />
        }
        bgText="02"
        page="/uslugi/transport-miedzynarodowy"
        imageFullWidth
        reverseLarge
      />

      <SectionImageText
        smallTitle="Usługi"
        title={
          <>
            Transport <br />
            ładunków <br />
            neutralnych
          </>
        }
        content={
          <>
            Ta usługa polega na przewozie ładunków, które nie wymagają
            spełnienia dodatkowych wymagań. Zlecenie transportu przyjmujemy
            z&nbsp;różnych sektorów gospodarki, takich jak np.: elektronika,
            meble, materiały budowlane, części samochodowe, maszyny, artykuły
            papiernicze. Do ich realizacji nie jest także potrzebne
            ponadstandardowe wyposażenie naczepy, czy specjalistyczne szkolenie
            kierowcy. Nie ma dla nas znaczenia wielkość i&nbsp;ilość
            przewożonych produktów – możemy je dostarczyć na paletach,
            w&nbsp;skrzyniach, kartonach, czy innych opakowaniach.
          </>
        }
        image={
          <StaticImage
            src="../images/nasze-uslugi/transport-towarow-neutralnych.jpg"
            alt=""
          />
        }
        bgText="03"
        page="/uslugi/transport-ladunkow-neutralnych"
        imageFullWidth
      />
      <SectionImageText
        smallTitle="Usługi"
        title={
          <>
            Transport <br />
            ponadgabarytowy
          </>
        }
        content={
          <>
            Przyjmujemy zlecenia na transport ładunków wielkogabarytowych, czyli
            takich, których wymiary przewyższają 16,5&nbsp;m całkowitej
            długości, 2,5&nbsp;m szerokości, 4&nbsp;m wysokości lub 42&nbsp;tony
            ciężaru całkowitego. Przewozimy m.in. różnego rodzaju konstrukcje
            stalowe i&nbsp;drewniane, maszyny przemysłowe i&nbsp;budowlane oraz
            ich elementy, zbiorniki, kombajny. Pomagamy w&nbsp;zorganizowaniu
            specjalistycznych zezwoleń, ubezpieczeń. Zaplanowanie takiego
            przedsięwzięcia wymaga przede wszystkim doboru właściwego taboru
            i&nbsp;uwzględnienia odpowiedniej trasy, na której nie będzie
            ograniczeń związanych z&nbsp;towarami ponadnormatywnymi.
          </>
        }
        image={
          <StaticImage
            src="../images/nasze-uslugi/transport-ponadgabarytowy.jpg"
            alt=""
          />
        }
        bgText="04"
        page="/uslugi/transport-ponadgabarytowy"
        imageFullWidth
        reverseLarge
      />

      <SectionImageText
        smallTitle="Usługi"
        title={
          <>
            Transport <br />
            materiałów <br /> niebezpiecznych
          </>
        }
        content={
          <>
            Dotyczy on ładunków, które zagrażają życiu lub zdrowiu ludzkiemu,
            środowisku naturalnemu, mieniu lub zwierzętom. Przewóz substancji
            trujących, zapalnych, zakaźnych i&nbsp;żrących jest dopuszczony
            tylko w&nbsp;warunkach określonych specjalnymi przepisami. Dbamy
            o&nbsp;to, aby maksymalnie ograniczyć skutki ewentualnego uwolnienia
            się materiałów niebezpiecznych. Realizacja zlecenia transportu
            odbywa się przy zapewnieniu specjalistycznego pojazdu,
            przeszkolonych kierowców, zaopatrzenia ich we właściwy sprzęt
            chroniący oraz z&nbsp;odpowiednim kompletem dokumentów.
          </>
        }
        image={
          <StaticImage
            src="../images/nasze-uslugi/transport-materialow-niebezpiecznych.jpg"
            alt=""
          />
        }
        bgText="05"
        page="/uslugi/transport-materialow-niebezpiecznych"
        imageFullWidth
      />
      <SectionImageText
        smallTitle="Usługi"
        title={
          <>
            Transport <br />
            w temperaturze <br />
            kontrolowanej
          </>
        }
        content={
          <>
            Ta usługa dotyczy ładunków chłodniczych, wymagających zachowania
            określonych parametrów temperaturowych, niezależnie od warunków
            atmosferycznych. Przewozimy je specjalnie wyposażonymi pojazdami,
            dzięki czemu nie ulegają uszkodzeniom i&nbsp;nie tracą właściwości
            konsumpcyjnych. Realizujemy przewozy towarów takich jak m.in.
            artykuły spożywcze, gastronomiczne, kosmetyki, rośliny
            w&nbsp;temperaturze od -25 stopni&nbsp;C do +25 stopni&nbsp;C.
          </>
        }
        image={
          <StaticImage
            src="../images/nasze-uslugi/transport-w-temperaturze-kontrolowanej.jpg"
            alt=""
          />
        }
        bgText="06"
        page="/uslugi/transport-w-temperaturze-kontrolowanej"
        imageFullWidth
        reverseLarge
      />

      <SectionImageText
        smallTitle="Usługi"
        title={
          <>
            Transport <br />
            ładunków <br />
            sypkich
          </>
        }
        content={
          <>
            Jeżeli chcesz zlecić profesjonalny transport ładunków sypkich,
            możesz powierzyć nam to zadanie. Sprawnie i&nbsp;bezpiecznie
            dostarczamy do celu towary, niezależnie od ich ilości i&nbsp;typu.
            Dysponujemy zasobami kadrowymi oraz możliwościami sprzętowymi, które
            umożliwiają wydajną i&nbsp;konkurencyjną cenowo realizację nawet
            najbardziej skomplikowanych zleceń. Wiemy jak powinien przebiegać
            fachowy przewóz ładunków sypkich i&nbsp;odpowiadamy za jego
            kompleksową realizację. Skontaktuj się z&nbsp;nami już teraz
            i&nbsp;zleć transport swoich towarów!
          </>
        }
        image={
          <StaticImage
            src="../images/nasze-uslugi/transport-ladunkow-sypkich.jpg"
            alt=""
          />
        }
        bgText="07"
        page="/uslugi/transport-ladunkow-sypkich"
        imageFullWidth
      />

      <SectionBgImageText
        smallTitle="Usługi"
        title="Profesjonalna realizacja&nbsp;zleceń"
        lead={
          <>
            Klientom oferujemy przewozy towarowe przy zachowaniu maksymalnie
            wysokiego poziomu bezpieczeństwa i&nbsp;w&nbsp;możliwie najkrótszym
            czasie. Kierowcy dysponują wymaganymi uprawnieniami do świadczenia
            przewozów standardowych oraz ADR, ponadgabarytowych czy
            chłodniczych.
          </>
        }
        content={
          <p>
            Aby przewóz ładunków przebiegał sprawnie, musi zostać właściwie
            zaplanowany. Wykwalifikowani pracownicy zajmują się logistyką,
            spedycją i&nbsp;organizacją transportów. Z&nbsp;racji tego, że
            współpracujemy z&nbsp;Klientami z&nbsp;różnych branż, zawsze
            proponujemy rozwiązania najlepiej odpowiadające Ich potrzebom. Na
            bieżąco monitorujemy wszystkie zlecenia, jakie otrzymaliśmy do
            realizacji.
          </p>
        }
        bgImage={sectionImages}
        bgLight
        reverseLarge
      />
      <SectionIconsSlider
        smallTitle="Usługi"
        title={
          <>
            Korzyści ze współpracy <br />z Nordic Logistics
          </>
        }
        content={
          <>
            <p className="lead">
              Proponowany przez nas przewóz ładunków niesie za sobą szereg
              korzyści.
            </p>
            <p className="lead">Do głównych zalet zaliczyć można m.in.:</p>
          </>
        }
        secondContent={
          <p className="lead">
            Zapraszamy do zapoznania się ze szczegółami naszej oferty
            i&nbsp;składania zleceń na&nbsp;przewóz towarów!
          </p>
        }
        items={[
          {
            icon: <IconSkarbonka />,
            title: (
              <>
                niskie <br />
                koszty
              </>
            ),
            content: (
              <>
                dokładamy wszelkich starań, aby każde zlecenie transportu było
                opłacalne dla Klienta
              </>
            ),
          },
          {
            icon: <IconFlota />,
            title: (
              <>
                nowoczesna <br />
                flota
              </>
            ),
            content: (
              <>
                dedykowane pojazdy wyposażone są w&nbsp;niezbędne
                zabezpieczenia, urządzenia i&nbsp;przyczepy
              </>
            ),
          },
          {
            icon: <IconParasol />,
            title: 'bezpieczeństwo',
            content: (
              <>
                wdrażamy rozwiązania, które mają na&nbsp;celu dostarczenie
                każdej przesyłki w&nbsp;nienaruszonym stanie, bez stwarzania
                zagrożenia dla&nbsp;ludzi czy&nbsp;środowiska
              </>
            ),
          },
          {
            icon: <IconElastycznosc />,
            title: (
              <>
                elastyczność <br />
                obsługi
              </>
            ),
            content: (
              <>
                udostępniamy możliwość dostaw całopojazdowych (FTL) oraz
                częściowych (LTL), w&nbsp;tym drobnicowych
              </>
            ),
          },
          {
            icon: <IconKalendarz />,
            title: 'Terminowość',
            content: <>zlecenia zawsze wykonujemy w&nbsp;ustalonym czasie</>,
          },
          {
            icon: <IconPracownik />,
            title: (
              <>
                wykwalifikowani <br />
                pracownicy
              </>
            ),
            content: (
              <>
                współpracujemy z osobami mającymi określone kompetencje,
                doświadczenie, uprawnienia i&nbsp;certyfikaty.
              </>
            ),
          },
        ]}
      />
    </Layout>
  )
}
